import { get, find, toLower, map, toNumber, isEmpty, groupBy } from "lodash";
import Image from "grandus-lib/components-atomic/image/Image";
import { HOOK_TYPE_HARD } from "grandus-lib/constants/AppConstants";

import styles from "components/product/card/ProductLabels.module.scss";
import upperFirst from "lodash/upperFirst";
import { useTranslation } from 'next-i18next';

const positionClasses = {
  1: "",
  2: styles.position2,
  3: styles.position3,
  4: styles.position4,
  5: styles.position5,
  6: styles.position6,
};

const ProductLabels = ({
  urlTitle,
  status,
  discount,
  new: newattr,
  favourite,
  kind,
  labels,
  condition,
  productHooks,
  isBig = false,
  options = {},
}) => {
  const { t } = useTranslation();
  let statusBackground = get(options, "background", "orange");
  if (status && toLower(status) == "predobjednávka") {
    statusBackground = "#ec3eac";
  }
  const showDelivery = kind && kind.id == 10;
  let showDiscount = false;
  if (discount && toNumber(parseInt(discount)) > 20) {
    showDiscount = true;
  }
  const showGift = find(productHooks, { type: HOOK_TYPE_HARD });

  const positionedLabels = groupBy(isEmpty(labels) ? [] : labels, (label) =>
    label?.position ? label.position : 1
  );

  // FIX: position 1 have to be always present do render base labels
  if (!get(positionedLabels, 6)) {
    positionedLabels[6] = null;
  }

  return (
    <>
      {map(positionedLabels, (labels, position) => {
        return (
          <LabelPosition
            labels={labels}
            urlTitle={urlTitle}
            position={position}
            isBig={isBig}
            key={`product-card-labels-custom-position-${urlTitle}-${position}`}
          >
            {position == 6 ? (
              <>
                {showDiscount ? (
                  <div className={`${styles.label} ${styles.discount}`}>
                    {upperFirst(t('common:discount'))} -{discount}
                  </div>
                ) : (
                  ""
                )}
                {favourite ? (
                  <div className={`${styles.label} ${styles.favourite}`}>
                    {upperFirst(t('common:favorite'))}
                  </div>
                ) : (
                  ""
                )}
                {status && status !== condition ? (
                  <div
                    className={`${styles.label} ${styles.status}`}
                    style={{ backgroundColor: statusBackground }}
                  >
                    {status}
                  </div>
                ) : (
                  ""
                )}
                {newattr ? (
                  <div className={`${styles.label} ${styles.new}`}>{upperFirst(t('common:news'))}</div>
                ) : (
                  ""
                )}
                {condition ? (
                  <div className={`${styles.label} ${styles.condition}`}>
                    {condition}
                  </div>
                ) : (
                  ""
                )}
                {showDelivery ? (
                  <div className={`${styles.label} ${styles.delivery}`}>
                    {upperFirst(t('common:gift'))}
                  </div>
                ) : (
                  ""
                )}
                {showGift ? (
                  <div className={`${styles.label} ${styles.gift}`}>{upperFirst(t('common:gift'))}</div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </LabelPosition>
        );
      })}
    </>
  );
};

const LabelPosition = ({
  labels = [],
  urlTitle,
  children,
  position = 1,
  isBig = false,
}) => {
  const grouppedLabels = groupBy(
    isEmpty(labels) ? [] : labels,
    (label) => label.group
  );

  return (
    <div
      className={`${styles.labels} ${get(positionClasses, [position], "")} ${
        isBig ? styles.isBig : ""
      }`}
    >
      {children}
      {labels
        ? map(grouppedLabels, (labelGroup, labelGroupName) => {
            if (labelGroupName) {
              return (
                <LabelGrouped
                  labelGroup={labelGroup}
                  labelGroupName={labelGroupName}
                  key={`product-card-labels-custom-${urlTitle}-${labelGroupName}`}
                />
              );
            } else {
              return map(labelGroup, (label, index) => {
                return (
                  <LabelSingle
                    label={label}
                    isBig={isBig}
                    key={`product-card-labels-custom-${urlTitle}-${index}-${get(
                      label,
                      "name"
                    )}`}
                  />
                );
              });
            }
          })
        : ""}
    </div>
  );
};

const LabelGrouped = ({ labelGroup, labelGroupName, urlTitle }) => {
  return (
    <div
      className={`${styles.label}`}
      style={{
        backgroundColor: get(labelGroup, "[0].backgroundColor"),
        color: get(labelGroup, "[0].textColor"),
      }}
    >
      {labelGroupName}:
      {map(labelGroup, (label, index) => (
        <span
          style={{ marginLeft: "5px" }}
          key={`product-card-labels-custom-${urlTitle}-${index}-${labelGroupName}-${get(
            label,
            "name"
          )}`}
        >
          {get(label, "name")}
        </span>
      ))}
    </div>
  );
};

const LabelSingle = ({ label, isBig }) => {
  const style = {};
  if (get(label, "backgroundColor")) {
    style.backgroundColor = get(label, "backgroundColor");
  }

  if (get(label, "textColor")) {
    style.color = get(label, "textColor");
  }

  if (label?.hash == "image") {
    style.backgroundColor = "transparent";
    style.padding = "0";
  }

  return (
    <div className={`${styles.label}`} style={style}>
      {label?.hash !== "image" ? get(label, "name") : ""}
      {label?.photo ? (
        <Image photo={label.photo} size={isBig ? "50" : "25"} type={"png"} />
      ) : (
        ""
      )}
    </div>
  );
};

export default ProductLabels;
