
import useSWR from "swr";
import styles from "components/heureka/HeurekaReviews.module.scss";
import { map, get, filter, isEmpty, slice, find, toNumber, floor } from "lodash";
import { StarFilled, StarOutlined } from "@ant-design/icons";

const HeurekaReviews = ({ }) => {

    const { data: reviews } = useSWR(`/api/pages/heureka`,
        (url) => fetch(url).then((r) => r.json())
    );
    const { data: staticBlocks } = useSWR(`/api/lib/v1/blocks`,
        (url) => fetch(url).then((r) => r.json())
    );

    let filteredReviews = filter(reviews, function (item) { return !isEmpty(item?.data?.summary) && item?.data?.recommends == "1"; });

    if (isEmpty(filteredReviews)) {
        return "";
    }

    filteredReviews = slice(filteredReviews, 0, 3);

    const heurekaText = get(
        find(staticBlocks, ["hash", "heureka_homepage_text"]),
        "content",
        ""
    );

    return (
        <div className={styles.heurekaReviews}>
            <div className="container guttered">
                <div className={styles.reviewsContainer}>
                    <div
                        dangerouslySetInnerHTML={{ __html: heurekaText }}
                        className={styles?.text}
                    />
                    <div className={styles.reviews}>
                        {map(filteredReviews, (item, index) => {
                            const { id, data } = item;
                            const totalRating = toNumber(data.total_rating);
                            return (
                                <div className={styles.review} key={`heureka-review-item-${index}-${id}`}>
                                    <div className={styles.comment}>
                                        "{get(data, 'summary', '')}"
                                    </div>
                                    {(totalRating > 0) ? (
                                        <div className={styles.stars}>
                                            {[...Array(floor(totalRating))].map((e, i) => <StarFilled key={`heureka-review-item-${index}-${id}-starts-${i}`} style={{ fontSize: "1.3em", color: "#FEC10D"}}/>)}
                                            {(totalRating % 1 > 0) ? (
                                                <StarOutlined style={{ fontSize: "1.3em", color: "#FEC10D"}}/>
                                            ):("")}
                                    </div>
                                    ) : ("")}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeurekaReviews;
