import { useEffect } from "react";
import styles from "./index/index.page.module.scss";
import {
  reqGetHeaders,
  reqApiHost,
  getProductCardFields,
} from "grandus-lib/utils";
import EnhancedEcommerce from "utils/ecommerce";
import TagManager from "grandus-lib/utils/gtag";

import { Row, Col, Carousel } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { SearchIcon } from "components/_others/icons";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import map from "lodash/map";
import slice from "lodash/slice";

import Link from "next/link";

import AccessoryFinder from "components/_others/accessoryFinder/AccesoryFinder";
import MetaData from "grandus-lib/components-atomic/MetaData";
import Content from "grandus-lib/components-atomic/content/Content";
import ProductCard from "components/product/card/ProductCard";
import BlogCard from "components/blog/card/BlogCard";
import Image from "grandus-lib/components-atomic/image/Image";
import HeurekaReviews from "components/heureka/HeurekaReviews";
// import Instagram from "components/instagram/Instagram";
import { getCategoryLinkAttributes } from "grandus-lib/hooks/useFilter";

// LANGUAGES
import upperFirst from "lodash/upperFirst";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";

const CAROUSEL_COUNT = 6;
const CAROUSEL_FAVOURITE_COUNT = 5;

const ArrowLeft = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={className + " " + styles.arrow + " " + styles.left}
      onClick={onClick}
    >
      <LeftOutlined />
    </button>
  );
};

const ArrowRight = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={className + " " + styles.arrow + " " + styles.right}
      onClick={onClick}
    >
      <RightOutlined />
    </button>
  );
};

const HomepageContent = ({ pages }) => {
  if (!pages) return null;

  return (
    <>
      {map(pages, (page, index) => {
        const { id, content, customJavascript, customCss } = page;
        return (
          <Content
            content={content}
            css={customCss}
            js={customJavascript}
            key={`static-page-${id}-${index}`}
          />
        );
      })}
    </>
  );
};

const Homepage = (props) => {
  const {
    meta = {},
    banners = [],
    banners01 = [],
    banners02 = [],
    products = [],
    productsNew = [],
    blogs = [],
    homepageStaticPageLocation1 = [],
    homepageStaticPageLocation2 = [],
    homepageStaticPageLocation3 = [],
    homepageStaticPageLocation4 = [],
    homepageStaticPageLocation5 = [],
    categories = [],
    carousels = [],
  } = props;

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: CAROUSEL_COUNT,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  };

  let favouriteCarouselSettings = { ...carouselSettings };
  favouriteCarouselSettings.slidesToShow = CAROUSEL_FAVOURITE_COUNT;
  if (products.length < CAROUSEL_FAVOURITE_COUNT + 1) {
    favouriteCarouselSettings.infinite = false;
  }

  let newCarouselSettings = { ...carouselSettings };
  if (productsNew.length < CAROUSEL_COUNT + 1) {
    newCarouselSettings.infinite = false;
  }

  meta.options = {
    image: {
      dimensions: "1200",
      width: 1200,
      height: -1,
    },
  };
  meta.photo = get(banners, "[0].photo", {});

  const { t } = useTranslation();
  useEffect(() => {
    TagManager.push(
      EnhancedEcommerce.viewItemList(products, {id: "homepage favourite products", name: "homepage favourite products"})
    );
    // TagManager.push(
    //   EnhancedEcommerce.viewItemList(productsNew, {id: "homepage new products", name: "homepage new products"})
    // );
  }, []);

  return (
    <section className={styles.wrapper}>
      <MetaData {...meta} />

      {!isEmpty(homepageStaticPageLocation1) ? (
        <main className={"container"}>
          <HomepageContent pages={homepageStaticPageLocation1} />
        </main>
      ) : (
        ""
      )}

      <div className={styles.bannersRow}>
        <main className={"container"}>
          <div className={styles.content}>
            <div className={styles.sidebar}>
              {!isEmpty(banners01) ? (
                <>
                  {/* <h2>Užitočné odkazy</h2> */}
                  <div className={styles.customBanners}>
                    {map(slice(banners01, 0, 4), (banner, index) => {
                      const { id, url, photo, title } = banner;

                      if (!url) {
                        return (
                          <div
                            key={`banners-custom-${id}-${index}`}
                            className={styles.customBanner}
                          >
                            <div className={styles.imageWrapper}>
                              <Image
                                photo={photo}
                                size={"73x73@2x"}
                                type={"png"}
                                useNextImage={true}
                              />
                            </div>
                            <div className={styles.title}>{title}</div>
                          </div>
                        );
                      }
                      return (
                        <div
                          key={`banners-custom-${id}-${index}`}
                          className={styles.customBanner}
                        >
                          <Link href={url}>
                            <a>
                              <div className={styles.imageWrapper}>
                                <Image
                                  photo={photo}
                                  size={"73x73@2x"}
                                  type={"png"}
                                  useNextImage={true}
                                />
                              </div>
                              <div className={styles.title}>{title}</div>
                            </a>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles.divider}></div>
                </>
              ) : (
                ""
              )}
              <div className={styles.accessoryFinder}>
                <AccessoryFinder />
              </div>
            </div>
            <div className={styles.main}>
              <Carousel
                {...{
                  dots: true,
                  arrows: true,
                  infinite: true,
                  draggable: true,
                  prevArrow: <ArrowLeft />,
                  nextArrow: <ArrowRight />,
                  autoplay: true,
                  autoplaySpeed: 3000,
                }}
              >
                {map(banners, (banner, index) => {
                  const { id, url, photo } = banner;
                  const imageProps = { priority: index === 0 ? true : false };
                  if (!url) {
                    return (
                      <div
                        key={`banners-${id}-${index}`}
                        className={styles.homepageBanner}
                      >
                        <div className={styles.imageWrapper}>
                          <Image
                            photo={photo}
                            size={"1073x445"}
                            type={"jpg"}
                            useNextImage={true}
                            imageProps={imageProps}
                          />
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div key={`banners-${id}-${index}`}>
                      {/* <Link href={url}> */}
                      {/* <a className={styles.homepageBanner}> */}
                      <a href={url} className={styles.homepageBanner}>
                        <div className={styles.imageWrapper}>
                          <Image
                            photo={photo}
                            size={"1073x445"}
                            type={"jpg"}
                            useNextImage={true}
                            imageProps={imageProps}
                          />
                        </div>
                      </a>
                      {/* </Link> */}
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </main>
      </div>

      <div className={styles.searchRow}>
        <main className={"container"}>
          <div className={styles.content}>
            <input
              type="text"
              placeholder="Vyhľadávanie"
              data-id="search-input"
            />
            <button type="submit" data-id="search-button">
              <SearchIcon width={20} height={20} />
            </button>
          </div>
        </main>
      </div>

      <div className={styles.marketingRow}>
        <main className={"container"}>
          <div className={styles.content}>
            <div className={styles.accessoryFinder}>
              <AccessoryFinder wrapper={true} />
            </div>

            <div className={styles.categories}>
              {map(categories, (category, index) => {
                const { id, name, urlName, externalUrl, alternativePhoto } =
                  category;
                return (
                  <div
                    key={`homepage-category-${id}-${index}`}
                    className={styles.category}
                  >
                    <Link
                      {...getCategoryLinkAttributes(
                        urlName,
                        "",
                        {},
                        { absoluteHref: externalUrl }
                      )}
                      scroll={true}
                    >
                      <a className={`no-custom-style`}>
                        <div className={styles.content}>
                          <div className={styles.imageWrapper}>
                            <Image
                              photo={alternativePhoto}
                              size={"90x90@2x"}
                              type={"png"}
                              useNextImage={true}
                            />
                          </div>
                          <div className={styles.title}>{name}</div>
                        </div>
                      </a>
                    </Link>
                  </div>
                );
              })}

              {map(banners01, (banner, index) => {
                const { id, url, photo, title } = banner;
                if (!url) {
                  return (
                    <div
                      key={`banners-custom-marketing-row-${id}-${index}`}
                      className={`${styles.category} ${styles.banner}`}
                    >
                      <div className={styles.content}>
                        <div className={styles.imageWrapper}>
                          <Image
                            photo={photo}
                            size={"90x90@2x"}
                            type={"png"}
                            useNextImage={true}
                          />
                        </div>
                        <div className={styles.title}>{title}</div>
                      </div>
                    </div>
                  );
                }
                return (
                  <div
                    key={`banners-custom-marketing-row-${id}-${index}`}
                    className={`${styles.category} ${styles.banner}`}
                  >
                    <Link href={url}>
                      <a>
                        <div className={styles.content}>
                          <div className={styles.imageWrapper}>
                            <Image
                              photo={photo}
                              size={"90x90@2x"}
                              type={"png"}
                              useNextImage={true}
                            />
                          </div>
                          <div className={styles.title}>{title}</div>
                        </div>
                      </a>
                    </Link>
                  </div>
                );
              })}
            </div>

            <div className={styles.marketingInfo}>
              <HomepageContent pages={homepageStaticPageLocation2} />
            </div>
          </div>
        </main>
      </div>

      <main className={`container ${styles.carouselsRow}`}>
        <HomepageContent pages={homepageStaticPageLocation2} />

        {!isEmpty(products) ? (
          <>
            <h2 className={styles.title}>
              <span className={styles.textWrapper}>
                {upperFirst(t("home:special offer"))}
              </span>
            </h2>
            <div className={styles.productsWrapper}>
              <Carousel autoplay {...favouriteCarouselSettings}>
                {map(products, (product, index) => {
                  return (
                    <div key={`products-${product.id}-${index}`}>
                      <ProductCard {...product} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </>
        ) : (
          ""
        )}

        <HomepageContent pages={homepageStaticPageLocation3} />

        {/* {!isEmpty(productsNew) ? (
          <>
            <h2 className={styles.title}>
              <span className={styles.textWrapper}>
                {upperFirst(t("home:new products"))}
              </span>
            </h2>
            <div className={styles.productsWrapper}>
              <Carousel autoplay {...newCarouselSettings}>
                {map(productsNew, (product, index) => {
                  return (
                    <div key={`products-news-${product.id}-${index}`}>
                      <ProductCard {...product} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </>
        ) : (
          ""
        )} */}

        {!isEmpty(banners02) ? (
          <div className={styles["banners-location2-section"]}>
            <Row gutter={[0, 0]}>
              {map(banners02, (banner, index) => {
                const { id, url, photo } = banner;
                return (
                  <Col
                    xs={24}
                    md={12}
                    key={`banners-location-02-${id}-${index}`}
                  >
                    <div className={styles["homepage-banner"]}>
                      {url ? (
                        <Link href={url} scroll={true}>
                          <a>
                            <Image
                              photo={photo}
                              size={"720x257__cropped"}
                              type={"jpg"}
                              useNextImage={true}
                            />
                          </a>
                        </Link>
                      ) : (
                        <Image
                          photo={photo}
                          size={"720x257__cropped"}
                          type={"jpg"}
                          useNextImage={true}
                        />
                      )}
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        ) : (
          ""
        )}

        {!isEmpty(carousels) ? (
          <>
            {map(carousels, (carousel, index) => {
              const { id, title, entities, url } = carousel;
              if (isEmpty(entities)) {
                return "";
              }
              let customCarouselSettings = { ...carouselSettings };
              if (entities.length < CAROUSEL_COUNT + 1) {
                customCarouselSettings.infinite = false;
              }
              return (
                <div key={`custom-carousels-homepage-${id}-${index}`}>
                  {!isEmpty(url) ? (
                    <h2 className={styles.title}>
                      <Link href={url}>
                        <a>
                          <span className={styles.textWrapper}>{title}</span>
                        </a>
                      </Link>
                    </h2>
                  ) : (
                    <h2 className={styles.title}>
                      <span className={styles.textWrapper}>{title}</span>
                    </h2>
                  )}
                  <div className={styles.productsWrapper}>
                    <Carousel autoplay {...customCarouselSettings}>
                      {map(entities, (product, index) => {
                        return (
                          <div
                            style={{ padding: "20px", margin: "20px" }}
                            key={`products-${product.id}-${index}`}
                          >
                            <ProductCard {...product} />
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          ""
        )}
      </main>

      <HeurekaReviews />
      <HomepageContent pages={homepageStaticPageLocation4} />

      {!isEmpty(blogs) ? (
        <div className={styles.blogsRow}>
          <main className={"container"}>
            <h2 className={`${styles.title} ${styles.simple}`}>
              {upperFirst(t("home:articles"))}
            </h2>
            <Carousel
              {...{
                dots: true,
                arrows: true,
                draggable: true,
                prevArrow: <ArrowLeft />,
                nextArrow: <ArrowRight />,
                autoplay: true,
                autoplaySpeed: 3000,
                slidesToScroll: 1,
                slidesToShow: 3,
                infinite: blogs.length > 3,
                responsive: [
                  {
                    breakpoint: 500,
                    settings: {
                      slidesToShow: 1,
                    },
                  },
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 2,
                    },
                  },
                ],
              }}
            >
              {map(blogs, (blog, index) => {
                const { id } = blog;
                return (
                  <div key={`blogs-homepage-${id}-${index}`}>
                    <BlogCard {...blog} />
                  </div>
                );
              })}
            </Carousel>
          </main>
        </div>
      ) : (
        ""
      )}

      <HomepageContent pages={homepageStaticPageLocation5} />
    </section>
  );
};

export async function getStaticProps({ locale }) {
  let metaData = {};
  const req = {};

  let [
    banners,
    banners01,
    banners02,
    products,
    productsNew,
    blogs,
    homepageStaticPageLocation1,
    homepageStaticPageLocation2,
    homepageStaticPageLocation3,
    homepageStaticPageLocation4,
    homepageStaticPageLocation5,
    categories,
    carousels,
  ] = await Promise.all([
    fetch(`${reqApiHost(req)}/api/v2/banners?type=2`, {
      headers: reqGetHeaders(req),
    })
      .then((result) => result.json())
      .then((r) => {
        metaData = r.meta;
        return r.data;
      }),
    fetch(`${reqApiHost(req)}/api/v2/banners?type=5`, {
      headers: reqGetHeaders(req),
    })
      .then((result) => result.json())
      .then((r) => r.data),
    fetch(`${reqApiHost(req)}/api/v2/banners?type=6`, {
      headers: reqGetHeaders(req),
    })
      .then((result) => result.json())
      .then((r) => r.data),
    //static page location 1 - 6
    fetch(
      `${reqApiHost(
        req
      )}/api/v2/products?fields=${getProductCardFields()}&per-page=10&favourite=1&orderBy=priority-desc`,
      {
        headers: reqGetHeaders(req),
      }
    )
      .then((result) => result.json())
      .then((r) => r.data),
    fetch(
      `${reqApiHost(
        req
      )}/api/v2/products?fields=${getProductCardFields()}&per-page=12&orderBy=time-desc`,
      { headers: reqGetHeaders(req) }
    )
      .then((result) => result.json())
      .then((r) => r.data),
    fetch(`${reqApiHost(req)}/api/v2/blogs?per-page=6`, {
      headers: reqGetHeaders(req),
    })
      .then((result) => result.json())
      .then((r) => r.data),
    fetch(
      `${reqApiHost(
        req
      )}/api/v2/pages?location=6&expand=content,customCss,customJavascript`,
      {
        headers: reqGetHeaders(req),
      }
    )
      .then((result) => result.json())
      .then((r) => r.data),
    fetch(
      `${reqApiHost(
        req
      )}/api/v2/pages?location=9&expand=content,customCss,customJavascript`,
      {
        headers: reqGetHeaders(req),
      }
    )
      .then((result) => result.json())
      .then((r) => r.data),
    fetch(
      `${reqApiHost(
        req
      )}/api/v2/pages?location=10&expand=content,customCss,customJavascript`,
      {
        headers: reqGetHeaders(req),
      }
    )
      .then((result) => result.json())
      .then((r) => r.data),
    fetch(
      `${reqApiHost(
        req
      )}/api/v2/pages?location=11&expand=content,customCss,customJavascript`,
      {
        headers: reqGetHeaders(req),
      }
    )
      .then((result) => result.json())
      .then((r) => r.data),
    fetch(
      `${reqApiHost(
        req
      )}/api/v2/pages?location=12&expand=content,customCss,customJavascript`,
      {
        headers: reqGetHeaders(req),
      }
    )
      .then((result) => result.json())
      .then((r) => r.data),
    fetch(`${reqApiHost(req)}/api/v2/categories?depth=0`, {
      headers: reqGetHeaders(req),
    })
      .then((result) => result.json())
      .then((r) => r.data),
    fetch(`${reqApiHost(req)}/api/v2/carousels?location=1&type=1`, {
      headers: reqGetHeaders(req),
    })
      .then((result) => result.json())
      .then((r) => r.data),
  ]);

  const data = {
    meta: metaData,
    banners,
    banners01,
    banners02,
    products,
    productsNew,
    blogs,
    homepageStaticPageLocation1,
    homepageStaticPageLocation2,
    homepageStaticPageLocation3,
    homepageStaticPageLocation4,
    homepageStaticPageLocation5,
    categories,
    carousels,
  };

  return {
    props: {
      ...data,
      ...(await serverSideTranslations(locale, ["common", "home"])),
    },
    revalidate: 5,
  };
}

export default Homepage;
