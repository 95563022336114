import Link from "next/link";
import Image from "grandus-lib/components-atomic/image/Image";

import styles from "components/blog/card/BlogCard.module.scss";
import { useTranslation } from "next-i18next";

const BlogCard = ({ title, urlTitle, perex, photo, buttonElement = null }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.blogCardWrapper}>
      <Link href="/blog/[id]" as={`/blog/${urlTitle}`}>
        <a className={styles.blogCard}>
          <div className={styles?.content}>
            <Image
              photo={photo}
              size={"428x316"}
              type={"jpg"}
              useNextImage={true}
            />
            <h3 className={styles.name}>{title}</h3>
            <div
              className={styles.perex}
              dangerouslySetInnerHTML={{
                __html: perex,
              }}
            />
          </div>
          <div className={styles?.footer}>
            {buttonElement || <span className={styles.button}>detail</span>}
          </div>
        </a>
      </Link>
    </div>
  );
};

export default BlogCard;
