import Link from "next/link";
import { useRouter } from "next/router";
import { get } from "lodash";

import { scrollToTop } from "grandus-lib/utils";

import ProductLabels from "components/product/card/ProductLabels";
import Image from "grandus-lib/components-atomic/image/Image";

import { getUrlObject } from "utils/routes";
import Price from "grandus-lib/components-atomic/price/Price";

import styles from "components/product/card/ProductCard.module.scss";
import { useTranslation } from 'next-i18next';

const ProductCard = ({ options = {}, ...product }) => {
  const {
    name,
    urlTitle,
    storeStatus,
    finalPriceData,
    photo,
    status,
    discount,
    new: newattr,
    favourite,
    condition,
    kind,
    labels,
    productHooks,
  } = product;
  let storeStatusClass = "available";
  if (!get(storeStatus, "available")) {
    storeStatusClass = get(storeStatus, "deliveryDate.date")
      ? "arriving"
      : "unavailable";
  }
  const { t } = useTranslation();
  const router = useRouter();
  const queryObject = getUrlObject(
    "/produkt/[id]",
    { id: urlTitle },
    router?.locale
  );

  const onClickHandler = (e) => {
    e.preventDefault();
    router.push(queryObject).then(() => scrollToTop());
  };
  return (
    <div className={styles.productCardWrapper}>
      <Link href={queryObject} scroll={true}>
        <a className={styles.productCard} onClick={onClickHandler}>
          <div className={styles.header}>
            <Image photo={photo} size={"215x225"} type={"jpg"} />
            <ProductLabels
              {...{
                urlTitle,
                discount,
                new: newattr,
                status,
                favourite,
                condition,
                kind,
                labels,
                productHooks,
              }}
            />
          </div>
          <div className={styles.content}>
            <span className={styles.name}>{name}</span>
            <div
              className={`${styles.storeStatus} ${styles[storeStatusClass]}`}
            >
              {get(storeStatus, "message")}
            </div>
            <div className={styles.bottomRow}>
              <Price priceData={finalPriceData} className={styles.price} />
              {/* <span className={styles.button}>{t('common:detail')}</span> */}
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default ProductCard;
