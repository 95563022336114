import ecommerce from 'grandus-lib/utils/ecommerce';
import FBPixel from './fbpixel';

import get from 'lodash/get';
import set from 'lodash/set';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import clone from 'lodash/clone';
import sortBy from 'lodash/sortBy';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import sum from 'lodash/sum';

const EnhancedEcommerce = {
  flattenCategories: function (product) {
    let categories = [];
    if (isArray(get(product, 'categories[0]'))) {
      forEach(get(product, 'categories'), localCategories => {
        forEach(localCategories, localCategory => {
          if (!find(categories, ['id', localCategory.id])) {
            categories.push(localCategory);
          }
        });
      });
    } else {
      categories = product?.categories ?? [];
    }

    return sortBy(categories, 'id');
  },
  flatMapParameters: function (product) {
    let parameters = get(
      product,
      'parameters',
      get(product, 'detailedParameters', {}),
    );

    if (isArray(parameters)) {
      parameters = reduce(
        parameters,
        (carry, param) => {
          carry[param.name] = param.value;
          return carry;
        },
        {},
      );
    }

    return parameters;
  },
  prepareCartForTracking: function (cart) {
    const cartCopy = JSON.parse(JSON.stringify(cart));

    cartCopy.items = map(cartCopy.items, item => {
      return this.prepareItemForTracking(item);
    });

    return cartCopy;
  },
  prepareItemForTracking: function (item) {
    const itemCopy = clone(item);
    itemCopy.product = this.prepareProductForTracking(item.product);

    return itemCopy;
  },
  prepareProductForTracking: function (product) {
    const productCopy = clone(product);
    productCopy.parameters = this.flatMapParameters(productCopy);
    productCopy.categories = this.flattenCategories(productCopy);

    return productCopy;
  },
  getProductVariant: function (parameters) {
    const color = parameters?.Farba !== undefined ? parameters.Farba : '';
    const memory = get(parameters, 'Interná pamäť', '');
    return `${color} ${memory}`;
  },

  impressions: (
    products,
    list = undefined,
    options = { page: 1, perPage: 1 },
  ) => {
    const data = ecommerce.view_item_list(products, list, options);
    data.fbdata = FBPixel.products(products);
    return prepareData(data, 'eec.impressionView');
  },
  detail: product => {
    const data = ecommerce.view_item(product);
    data.ecommerce.fbdata = FBPixel.productDetail([product]);
    return prepareData(data, 'eec.detail');
  },
  productClick: (
    product,
    list = undefined,
    options = { page: 1, perPage: 1 },
  ) => {
    const data = ecommerce.productClick(product, list, options);
    return prepareData(data, 'eec.impressionClick');
  },
  cartAdd: function (product, quantity = 1) {
    const productToTrack = this.prepareProductForTracking(product);

    const data = ecommerce.add_to_cart(
      productToTrack,
      this.getProductVariant(productToTrack.parameters),
      quantity,
    );
    data.ecommerce.fbdata = FBPixel.productCart([
      { product: productToTrack, count: quantity },
    ]);
    return prepareData(data, 'eec.add');
  },
  cartRemove: function (item, quantity = 1) {
    const { product } = item;
    const itemToTrack = this.prepareItemForTracking(item);

    const data = ecommerce.remove_from_cart(itemToTrack, quantity);
    data.ecommerce.fbdata = FBPixel.productCart([{ product, count: quantity }]);
    return prepareData(data, 'eec.remove');
  },
  checkout: (cart, step = null) => {
    const data = ecommerce.begin_checkout(get(cart, 'items', []), step);
    data.ecommerce.checkout.fbdata = FBPixel.productsCheckout(cart);
    return prepareData(data, 'eec.checkout');
  },
  deliveryInfo: function (cart) {
    return ecommerce.add_shipping_info(this.prepareCartForTracking(cart));
  },
  paymentInfo: function (cart) {
    return ecommerce.add_payment_info(this.prepareCartForTracking(cart));
  },
  viewItem: function (product) {
    return ecommerce.view_item(this.prepareProductForTracking(product));
  },
  viewCart: function (cart) {
    return ecommerce.view_cart(this.prepareCartForTracking(cart));
  },
  beginCheckout: function (cart) {
    return ecommerce.begin_checkout(this.prepareCartForTracking(cart));
  },
  addToWishlist: function (product) {
    const productToTrack = this.prepareProductForTracking(product);
    return ecommerce.add_to_wishlist(
      productToTrack,
      this.getProductVariant(productToTrack.parameters),
    );
  },
  viewItemList: function (products, list, options) {
    return ecommerce.view_item_list(products, list, options);
  },
  purchase: order => {
    const data = {};
    data.fbdata = FBPixel.purchase(order);
    return prepareData(data, 'fb.purchase');
  },

  addProductsToCart: function (products) {
    const pixelData = [];
    const analyticsData = {
      items: [],
      currency: get(products, '[0].finalPriceData.currency'),
      value: sum(
        map(products, product => get(product, 'finalPriceData.price', 0)),
      ),
    };

    forEach(products, product => {
      const productToTrack = this.prepareProductForTracking(product);
      const data = ecommerce.add_to_cart(
        productToTrack,
        this.getProductVariant(productToTrack),
        1,
      );

      analyticsData.items.push(get(data, 'ecommerce.items[0]'));
      pixelData.push({
        product,
        productToTrack,
        quantity: 1,
      });
    });

    const ecommerceJson = {
      event: 'add_to_cart',
      ecommerce: analyticsData,
    };

    ecommerceJson.ecommerce.fbdata = FBPixel.productCart(pixelData);

    return prepareData(ecommerceJson, 'eec.add');
  },

  reservation: function (product) {
    const item = ecommerce.view_item(this.prepareProductForTracking(product));

    return prepareData(item, 'reservation');
  },
};

const prepareData = (data = {}, event = null) => {
  const ecommerceJson = data;
  if (event) {
    set(ecommerceJson, 'event', event);
  }

  return ecommerceJson;
};

export default EnhancedEcommerce;
